import * as React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

const FeaturedPost = (props) => {
  //console.log(props);
  const {post} = props;
  const title = post.node.frontmatter.title;
  const excerpt = post.node.excerpt;
  const featuredimage = post.node.frontmatter.featured
  return (
    <section className="section featured-post">
      <Link to={post.node.frontmatter.url}><h2 className="featured-title has-text-centered">{title}</h2></Link>
      <div className="featured-post-wrapper">
        <div className="featured-post-image">
          <Link to={post.node.frontmatter.url} className="featured-thumbnail">
            <PreviewCompatibleImage
              imageInfo={{
                image: featuredimage,
                alt: `featured image thumbnail for post ${title}`,
                width: 800,
                height: 380
              }}
            />
          </Link>
        </div>
        <div className="featured-post-content">
          <p className="featured-excerpt">{excerpt}</p>

          <div className="featured-post-read-more">
            <Link className="featured-post-link" to={post.node.frontmatter.url}>Read more</Link>
          </div>
        </div>
      </div>
    </section>
  )
};

FeaturedPost.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  featuredimage: PropTypes.object
};

export default FeaturedPost;
