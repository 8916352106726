import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const PAGE_COUNT = 3;

const BlogRollTemplate = (props) => {
  const [count, setCount] = useState(PAGE_COUNT)
  const {data: posts} = props;

  const onLoadMore = () => {
    setCount(count + PAGE_COUNT)
  }
    return (
      <div className="columns is-multiline section">
        {posts &&
          posts
            .slice(0, count)
            .map(({ node: post }) => (
            <div className="is-parent column is-full" key={post.id}>
              <article
                className={`blog-list-item is-child ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                <header className="post-content">
                  <p className="post-meta">
                    <Link
                      className="title"
                      to={post.frontmatter.url}
                    >
                      {post.frontmatter.title}
                    </Link>
                  </p>
                  <p className="post-excerpt">
                  {post.frontmatter.description}&nbsp;
                  <Link className="link" to={post.frontmatter.url}>
                    Read more
                  </Link>
                </p>
                </header>
                {post.frontmatter.normal ? (
                    <Link className="is-flex thumbnail-link" to={post.frontmatter.url}>
                      <div className="featured-thumbnail">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.normal,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                            width: 360,
                            height: 240,
                          }}
                        />
                      </div>
                    </Link>
                  ) : null}
              </article>
            </div>
          ))}
        {posts?.length && count < posts.length && <button className="button is-primary load-more" onClick={onLoadMore}>See Older Posts</button>}
      </div>
    )
}

BlogRollTemplate.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  featuredpost: PropTypes.object
}


export default BlogRollTemplate;
