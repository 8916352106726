import * as React from "react";
import {graphql} from "gatsby";
import BlogRoll from "../../components/BlogRoll";
import FeaturedPost from "../../components/FeaturedPost";
import SEO from "../../components/SEO";
const BlogIndexPage = (props) => {
  //console.log(props);
  let {edges: posts} = props.data.allMarkdownRemark;
  let featured = false;
  posts = posts.filter( (p) => {
    if(!p.node.frontmatter.featuredpost) {
      return p;
    }else{
      featured = p;
    }
    return p;
  });
  const seo = {
    frontmatter: {
      seo: {
        title: "Blog",
      },
    },
    fields: {
      slug: "/blog",
    },
  };

  return (
    <React.Fragment>
      <SEO {...seo} />
      <section className="blog-index-section">
        <h1 className="has-text-centered">Blog</h1>
        <div className="content">
          <FeaturedPost post={featured}/>
          <BlogRoll data={posts}/>
        </div>
      </section>
    </React.Fragment>
  );
}
export default BlogIndexPage;
export const BlogQuery = graphql`
  query BlogRollQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { filterKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            description
            date(formatString: "MMMM DD, YYYY")
            featuredpost
            url
            normal: featuredimage {
              childImageSharp {
                gatsbyImageData(
                  width: 350
                  quality: 100
                  layout: CONSTRAINED
                )
              }
            }
            featured: featuredimage {
              childImageSharp {
                gatsbyImageData(
                  width: 1040
                  height: 600
                  quality: 100
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
      }
    }
  }
`;
